import React from "react";
import DevelopBannerSection from "../components/Homepage/DevelopPageComponents/DevelopBannerSection";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HealthSliderCard from "../components/HealthcarePageComponent/HealthSliderCard";
import IndusExpert from "../components/IndustriesPageComponents/IndusExpert";
import { Accodion } from "../components/Accordion/AccordionItem";
import FaqVideo from "../components/FaqVideo/FaqVideo";
import TransparentRoundedBtn from "../components/CTA/TransparentRoundedBtn";
import {
    arvindprofileImg,
  float1,
  float2,
  mouseSlide10,
  mouseSlide11,
  mouseSlide12,
  mouseSlide14,
  mouseSlide15,
  mouseSlide9,
} from "../components";
import DevDiff from "../components/Homepage/DevelopPageComponents/DevDiff";
import HealthCareTabSection from "../components/HealthcarePageComponent/HealthCareTabSection";
import HealthcareTabs from "../components/Tabs/HealthcareTabs";
import ExpertButton from "../components/CTA/ExpertButton";
import ScrollingSlide from "../components/MouseScrollingComponent/ScrollingSlide";
import { Helmet } from "react-helmet";
function Travel() {
  var settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    speed: 800,
    adaptiveHeight: false,
    infinite: true,
    useTransform: true,
    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section id="healthCare" className="healthCare travelBg">
      <Helmet>
<title>Travel App Development Services</title>
<meta name="description" content="Take your travel business to the next level with custom app development. We create intuitive booking, management, and customer interaction apps that deliver seamless travel experiences." />

<link rel="canonical" href="https://version-next.com/travel" />
    
<meta property="og:title" content="Travel App Development Services" />
<meta property="og:description" content="Take your travel business to the next level with custom app development. We create intuitive booking, management, and customer interaction apps that deliver seamless travel experiences." />
<meta property="og:url" content="https://version-next.com/travel" />
<meta property="og:type" content="website" />
<meta property="og:image" content="https://version-next.com/static/media/versionnext-logo.abca9ec7.svg" />

<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="Travel App Development Services" />
<meta name="twitter:description" content="Take your travel business to the next level with custom app development. We create intuitive booking, management, and customer interaction apps that deliver seamless travel experiences." />
<meta name="twitter:image" content="https://version-next.com/static/media/versionnext-logo.abca9ec7.svg" />
</Helmet>
      <div className="devTopSection">
        <DevelopBannerSection
          line1="We Provide"
          line2="Travel App"
          line3="Development Services"
          BannerPara="We assist travel enterprises and brands by developing tailored web and mobile applications using the latest technologies. These applications empower them to maintain their competitive edge and connect with expansive audiences across worldwide destinations."
        />
        <div className="shapeSvg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                className="fil0"
                d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
              />
            </g>
          </svg>
        </div>
      </div>
      <div className="healthServicesSliderWrap">
        <div className="container">
          <div className="row sectionFlexHeading">
            <div className="col-md-6 col-12">
              <div className="sectionTitle">
                <h2>Our Top-tier Travel App Development Services</h2>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <p className="sectionPara">
                Experience unparalleled excellence with our leading Travel App
                Development Services. We specialize in crafting innovative and
                high-performing travel applications that redefine industry
                standards.
              </p>
            </div>
          </div>
          <div className="healthServicesSlider">
            <Slider {...settings} className="slider-with-gap">
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Travel Guide Apps"
                  cardPara="Create dynamic applications that furnish travelers with essential insights regarding their preferred destinations. Tailoring the app according to your distinct requirements, we incorporate dedicated user panels that ensure a personalized experience."
                  cardLink=""
                  btnName="Get consultation"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Travel Marketplace"
                  cardPara="Our development team is committed to constructing a resilient platform that guarantees a steady flow of revenue for businesses while delivering a user-friendly and convenient experience for end customers."
                  cardLink=""
                  btnName="Leave a request"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Transport App"
                  cardPara="Our transportation applications optimize travel arrangements, saving users both time and money while streamlining transportation processes for enhanced convenience."
                  cardLink=""
                  btnName="Order an estimate"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Hotel Booking App"
                  cardPara="Deliver a flawless hotel booking journey with our specialized expertise in developing custom hotel booking apps. We combine strong and secure customer resources to construct a user-friendly interface that caters to both vendors and end-users seamlessly."
                  cardLink=""
                  btnName="Share your idea"
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <section id="healthCareTabSection">
        <div className="container">
          <HealthCareTabSection
            tabSectionTitle1="We Provide"
            tabSectionTitle2="Travel Software "
            tabSectionTitle3="Solutions"
            tabSectionPara="Our suite of travel software solutions is designed to streamline operations, boost efficiency, and deliver seamless user experiences across various segments of the travel ecosystem."
          />
          <HealthcareTabs
            tabTitle1="Travel Management System (TMS)"
            tabTitle2="Online Booking Applications"
            tabTitle3="Travel Analytics and Reporting"
            tabTitle4="Payment Gateway Integration"
            tabContentTitle11="Travel Management System (TMS)"
            contentList11="Booking Management"
            contentList12="Itinerary Creation"
            contentList13="Customer Profiles"
            contentList14="Supplier Integration"
            contentList15="Financial Management"
            tabContentTitle22="Online Booking Applications"
            contentList21="User-Friendly Interface"
            contentList22="Comprehensive Search Filters"
            contentList23="Real-Time Availability"
            contentList24="Dynamic Pricing"
            contentList25="Secure Payment Processing"
            contentList26="Social Sharing and Reviews"
            tabContentTitle33="Travel Analytics and Reporting"
            contentList31="Security testing"
            contentList32="Performance Metrics Dashboard"
            contentList33="Customizable Reports"
            contentList34="Revenue Analysis"
            contentList35="Customer Behavior Insights"
            contentList36="Conversion Rates"
            tabContentTitle44="Payment Gateway Integration"
            contentList41="Diverse Payment Methods"
            contentList42="Multi-Currency Support"
            contentList43="Secure Transactions"
            contentList44="One-Click Payments"
            contentList45="Mobile Optimization"
            contentList46="Fast and Reliable Processing"
          />
        </div>
      </section>
      <section
        className="mouseScrollingSliderSection"
        id="mouseScrollingSliderSection"
      >
        <div className="container">
          <div className="sectionTitle">
            <h2>
              {" "}
              Work <span className="textGrade">Portfolio</span>
            </h2>
            <ExpertButton
              rText="Get Project Quote"
              rName="rAnimeBtn qouteBtn"
            />
          </div>
        </div>
        <ScrollingSlide
          scrollSliderImg1={mouseSlide9}
          scrollSliderImg2={mouseSlide10}
          scrollSliderImg3={mouseSlide11}
          scrollSliderImg4={mouseSlide12}
          scrollSliderImg6={mouseSlide14}
          scrollSliderImg7={mouseSlide15}
        />
      </section>
      <section className="IndusExpertSection">
        <IndusExpert
          cName="Version Next Technologies"
          cSecTitle="Why Hire Version Next Technologies For Travel App Solutions?"
          profileName="Mr. Arvind Baraskar"
          designation="Buisness Head"
          cardTitle1="Expertise In Travel Technology"
          cardData1="Version Next Technologies boasts a team of seasoned experts with extensive experience in the travel and technology sectors."
          cardTitle2="Tailored Solutions For Your Business"
          cardData2="We don't believe in one-size-fits-all solutions. Our approach is personalized, ensuring that our travel app solutions align seamlessly with your business model."
          cardTitle3="End-to-End Services"
          cardData3="We offer comprehensive services, from conceptualization and development to testing, deployment, and ongoing support."
          cardTitle4="Data Security and Privacy"
          cardData4="We prioritize the security of customer data and adhere to industry standards and regulations. You can trust that sensitive information is handled with the utmost care and diligence."
          profileImg={arvindprofileImg}
          facebookLink="https://www.facebook.com/arvindv.baraskar"
          facebookIcon="fab fa-facebook-square"
          twiiterLink="https://twitter.com/ArvindBaraskar"
          twitterIcon="fab fa-twitter-square"
          linkdinLink="https://www.linkedin.com/in/arvindbaraskar/"
          linkdinIcon="fab fa-linkedin"
        />
      </section>
      <section className="whatMakesSection" id="whatMakesSection">
        <DevDiff
          float1={float1}
          float2={float2}
          Char1="What Makes"
          Char2="us"
          Char3="Different?"
          diffDescription="At Version Next Technologies, We thrive on innovation. Our teams are constantly exploring new technologies, methodologies, and ideas to bring fresh, creative solutions to the table. This drive for innovation ensures that our clients receive cutting-edge products that keep them ahead of the curve."
        />
      </section>
      <section className="commonFaq">
        <div className="container">
          <div className="sectionTitle">
            <h2>
              Frequently Asked <span className="textGrade">Questions</span>
            </h2>

            <p>
              Singling out common points and possible difficulties which can be
              encountered in EHR, EMR, and Patient Portal software development
            </p>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-6 col-12">
              <Accodion />
            </div>
            <div className="col-md-12 col-lg-6 col-12">
              <FaqVideo />
            </div>
          </div>
          <div className="readMoreBtnWrap">
            <TransparentRoundedBtn
              rText="Read More"
              rLink="/faqs"
              rName="rAnimeBtn readMoretBtn"
            />
          </div>
        </div>
      </section>
    </section>
  );
}

export default Travel;
