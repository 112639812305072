import React from "react";
import DevelopBannerSection from "../components/Homepage/DevelopPageComponents/DevelopBannerSection";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HealthSliderCard from "../components/HealthcarePageComponent/HealthSliderCard";
import IndusExpert from "../components/IndustriesPageComponents/IndusExpert";
import { float1, float2, vinayakprofileImg } from "../components";
import DevDiff from "../components/Homepage/DevelopPageComponents/DevDiff";
import CaseStudyNew from "../components/Homepage/CaseStudy/CaseStudyNew";
import HealthCareTabSection from "../components/HealthcarePageComponent/HealthCareTabSection";
import HealthcareTabs from "../components/Tabs/HealthcareTabs";
import { Helmet } from "react-helmet";
function Education() {
  var settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    speed: 800,
    adaptiveHeight: false,
    infinite: true,
    useTransform: true,
    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section id="healthCare" className="healthCare eduTech">
      <Helmet>
<title>Education Software Development Company</title>
<meta name="description" content="Innovate learning with our education software development services. We offer solutions for e-learning platforms, student management, and interactive educational tools to enhance the learning experience." />

<link rel="canonical" href="https://version-next.com/education-software-development-company" />
    
<meta property="og:title" content="Education Software Development Company" />
<meta property="og:description" content="Innovate learning with our education software development services. We offer solutions for e-learning platforms, student management, and interactive educational tools to enhance the learning experience." />
<meta property="og:url" content="https://version-next.com/education-software-development-company" />
<meta property="og:type" content="website" />
<meta property="og:image" content="https://version-next.com/static/media/versionnext-logo.abca9ec7.svg" />

<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="Education Software Development Company" />
<meta name="twitter:description" content="Innovate learning with our education software development services. We offer solutions for e-learning platforms, student management, and interactive educational tools to enhance the learning experience." />
<meta name="twitter:image" content="https://version-next.com/static/media/versionnext-logo.abca9ec7.svg" />
</Helmet>
      <div className="devTopSection">
        <DevelopBannerSection
          line1="Education"
          line2="Software Development"
          line3="Company"
          BannerPara="Revolutionize the educational experience with our cutting-edge edtech app development services. We are a leading education software development company dedicated to overcoming the challenges that hinder accessibility, affordability, and engagement in the education process."
        />
        <div className="shapeSvg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                className="fil0"
                d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
              />
            </g>
          </svg>
        </div>
      </div>
      <div className="healthServicesSliderWrap">
        <div className="container">
          <div className="row sectionFlexHeading">
            <div className="col-md-6 col-12">
              <div className="sectionTitle">
                <h2>High-Quality Educational Software Solutions We Offer</h2>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <p className="sectionPara">
                Join us as we redefine education through our exceptional
                software, shaping a brighter future for learners and educators
                alike. Together, let's unlock the full potential of education
                and inspire a lifelong passion for knowledge acquisition.
              </p>
            </div>
          </div>
          <div className="healthServicesSlider">
            <Slider {...settings} className="slider-with-gap">
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Learning Management System"
                  cardPara="Discover the potential of our Learning Management System and transform your educational institution into a hub of interactive and impactful learning experiences. Unleash the power of technology to streamline education, nurture progress, and achieve academic excellence."
                  cardLink=""
                  btnName="Get consultation"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="School Management Software"
                  cardPara="Join the league of modern educational institutions and revolutionize the way your school operates. Embrace the power of our School Management Software and create an environment that fosters growth, efficiency, and excellence in education."
                  cardLink=""
                  btnName="Leave a request"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Virtual Classroom Solutions"
                  cardPara="Our Virtual Classroom Solutions redefine the way teachers and students connect for remote education. We create dynamic virtual engagement spaces that foster interactive and immersive learning experiences."
                  cardLink=""
                  btnName="Order an estimate"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="e-Learning Application Development"
                  cardPara="Our e-Learning Application Development services empower users to access education seamlessly across all devices. With our innovative eLearning solutions, we ensure that learning becomes accessible to everyone, regardless of the device they use."
                  cardLink=""
                  btnName="Share your idea"
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <section id="healthCareTabSection">
        <div className="container">
          <HealthCareTabSection
            tabSectionTitle1="Education"
            tabSectionTitle2="Software Solutions"
            tabSectionTitle3="We Provide"
            tabSectionPara="As an educational software development company, we specialize in crafting next-generation education solutions to create a seamless and advanced education ecosystem. Our expertise lies in building cutting-edge software that empowers educators and learners alike, fostering a transformative learning experience for all."
          />
          <HealthcareTabs
            tabTitle1="Staff Management Software"
            tabTitle2="Smart Planner"
            tabTitle3="Student Information System (SIS)"
            tabTitle4="Online Exam Software"
            tabContentTitle11="Staff Management Software"
            contentList11="Employee Information Management"
            contentList12="Attendance Tracking"
            contentList13="Performance Evaluation"
            contentList14="Task and Project Assignment"
            contentList15="Leave and Vacation Management"
            contentList16="Employee Self-Service"
            tabContentTitle22="Smart Planner"
            contentList21="Lesson Planning and Scheduling"
            contentList22="Personalized Learning Paths"
            contentList23="Resource Management"
            contentList24="Assessment and Progress Tracking"
            contentList25="Goal Setting and Achievement Tracking"
            contentList26="Parental Engagement"
            tabContentTitle33="Student Information System (SIS)"
            contentList31="Student Records Management"
            contentList32="Course Management"
            contentList33="Grading and Assessment"
            contentList34="Communication and Collaboration"
            contentList35="Reporting and Analytics"
            contentList36="Billing and Financial Management"
            tabContentTitle44="Online Exam Software"
            contentList41="Question Bank and Test Creation"
            contentList42="Test Configuration and Settings"
            contentList43="Security and Anti-cheating Measures"
            contentList44="Customization and Branding"
            contentList45="Accessibility and Compatibility"
            contentList46="Real-time Monitoring and Reporting"
          />
        </div>
      </section>
      <section id="case_study" className="case_study">
        <CaseStudyNew
          titleChar1="We Provide"
          titleChar2="Software Solutions"
          projectTitle10="1. Utsav Jewels"
          projectPara11="Utssav Jwelles had a strong presence in the offline market but wanted to expand their reach to a wider audience by selling their products online. They approached us at Version Next to help them needed a user-friendly e-commerce website that provided a seamless shopping experience to their customers."
          projectPara12="To address Utssav Jwelles' requirements, we designed and developed an e-commerce with mobile app for Utssav Jwelles to manage their orders and deliveries. The app allowed them to view all orders and track their status in real-time. The application was built using a modern stack of technologies to ensure fast loading times and smooth checkout processes."
          projectPara13="At Version Next, we take pride in delivering high-quality solutions that are tailored to our client's business needs. Our collaboration with Utssav Jwelles is an example of how we leverage our expertise in web and mobile app development to help businesses achieve their goals."
          projectTitle20="2. Building an ERP Tool for Heritage"
          projectPara21="Heritage, a leading company in the industry, sought to enhance their customer experience by developing an ERP tool. This tool aimed to seamlessly connect customers with the ability to order subscription-based products through their app. Additionally, it would establish a connection to the nearest Heritage branch responsible for fulfilling the orders and facilitating timely deliveries. Version Next, an experienced IT services provider, was enlisted to build this innovative solution."
          projectPara22="Version Next's development team meticulously crafted the ERP tool, leveraging industry-standard technologies to ensure a robust and reliable solution. The user interface was designed with a customer-centric approach, focusing on simplicity and ease of use while offering a comprehensive ordering experience. The ERP tool transformed Heritage's customer experience by providing a convenient, user-friendly platform for ordering subscription-based products."
          projectPara23="The solution provided Heritage with a competitive edge by enhancing customer satisfaction, optimizing internal operations, and supporting future growth."
          totlaCount="2"
        />
      </section>
      <section className="IndusExpertSection">
        <IndusExpert
          cName="Version Next Technologies"
          cSecTitle="Why Hire Version Next Technologies For Education Software Development?"
          profileName="Mr. Vinayak Parab"
          designation="Buisness Head"
          cardTitle1="Innovation"
          cardData1="We brings innovative solutions that leverage cutting-edge technologies to drive business growth and stay ahead of the competition."
          cardTitle2="Integrity"
          cardData2="We uphold the highest standards of integrity, ensuring transparency, honesty, and ethical practices in all our interactions with clients."
          cardTitle3="Customer-Obsession"
          cardData3="Our unwavering focus on delivering exceptional customer experiences sets us apart, as we go above and beyond to meet and exceed client expectations."
          cardTitle4="Quality"
          cardData4="We are committed to delivering top-notch quality in all our services, adhering to industry best practices and rigorous quality control processes to ensure excellence."
          profileImg={vinayakprofileImg}
          linkdinLink="http://linkedin.com/in/vinayak-parab-2a7a8615"
          linkdinIcon="fab fa-linkedin"
        />
      </section>
      <section className="whatMakesSection" id="whatMakesSection">
        <DevDiff
          float1={float1}
          float2={float2}
          Char1="What Makes"
          Char2="us"
          Char3="Different?"
          diffDescription="What sets us apart is our expertise, customization, collaboration, creativity, results-driven approach, and customer-centric focus. We have a highly skilled team, personalized solutions, open communication, innovative ideas, a focus on measurable results, and a dedication to customer satisfaction. These factors combine to deliver outstanding outcomes and exceptional experiences for our clients."
        />
      </section>
    </section>
  );
}

export default Education;
