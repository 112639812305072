import React from "react";
// import CommonRoundButton from "../components/CTA/CommonRoundButton";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import WhychooseUs from "../components/Homepage/WhyChooseUs/WhychooseUs";
import Clients from "../components/Homepage/Clients/Clients";
import ContactUs from "../components/Homepage/ContactUs/ContactUs";
import CaseStudyNew from "../components/Homepage/CaseStudy/CaseStudyNew";
import Lottie from "react-lottie";
import DevAnime from "../components/Homepage/HomeSliderAnimation/DevolopedAnime";
import EcommAnime from "../components/Homepage/HomeSliderAnimation/EcommAnime";
import AiAnime from "../components/Homepage/HomeSliderAnimation/AiAnime";
import UiUxAnime from "../components/Homepage/HomeSliderAnimation/UiUxAnime.json";
import MeetingFixed from "../components/Homepage/FixedMeeting/MeetingFixed";
import WhyChooseUsNew from "../components/Homepage/WhyChooseUsNew/WhyChooseUsNew";
import RoundedCommonBtn from "../components/CTA/RoundedCommonBtn";
// import { blackarrow, whitearrow } from "../components";
import Industries from "../components/Homepage/IndustriesSection/Industries";
import TechStack from "../components/Homepage/TechnologyStack/TechStack";
import ScrollTriggerPage from "../components/Homepage/ScrollTriggerPage";
import OurProcess from "../components/OurProcess/OurProcess";
import { Helmet } from "react-helmet";

function HomePage(props) {
  var settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    speed: 800,
    adaptiveHeight: false,
    infinite: true,
    useTransform: true,
    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: DevAnime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: AiAnime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions3 = {
    loop: true,
    autoplay: true,
    animationData: EcommAnime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions4 = {
    loop: true,
    autoplay: true,
    animationData: UiUxAnime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
        <Helmet>
          <title>Version Next | Mobile App, Web App, CRM, ERP Solutions Development Company in Mumbai</title>
          <meta name="description" content="We are a leading software development company in Mumbai, offering top-notch services in mobile app development, web app development, CRM, and ERP solutions. Transform your business with our expertise." />
          <meta name="keywords" content="software development, Mumbai, mobile app development, web app development, CRM solutions, ERP solutions, software company" />
       </Helmet>
      <section id="homeHeader" className="homeHeader">
        <div className="container">
          <Slider {...settings}>
            <div className="row d-flex">
              <div className="col-md-12 col-lg-6">
                <div className="homeHeaderLeftBox">
                  <div className="headerMainTextWrap">
                    <h1>
                      <span className="turstedText">Your Trusted</span>

                      <span className="devText">Development</span>

                      <span className="partnerText">Partner</span>
                    </h1>
                  </div>
                  <p className="homePara">
                    You need a new mobile app, web application, or enterprise
                    software, we can help you bring your vision to life. We are
                    committed to delivering high-quality software solutions that
                    are scalable, secure & user-friendly.
                  </p>
                  <RoundedCommonBtn
                    rText="Know More"
                    rLink="/web-app-development"
                    rName="rAnimeBtn homeBannerBtn"
                  />
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="homeHeaderRighttBox">
                  <div className="image_container">
                    <Lottie options={defaultOptions} className="animeGIf" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex">
              <div className="col-md-12 col-lg-6">
                <div className="homeHeaderLeftBox">
                  <div className="headerMainTextWrap">
                    <h1>
                      <span className="turstedText">Machine </span>

                      <span className="devText">Ai & Data</span>

                      <span className="partnerText">Solutions</span>
                    </h1>
                  </div>
                  <p className="homePara">
                    With our advanced AI technology, we can help you improve
                    operational efficiency, streamline processes, automate
                    routine tasks, enhance customer experiences to achieve your
                    goals.
                  </p>
                  <RoundedCommonBtn
                    rText="Know More"
                    rLink="/ai-and-ml"
                    rName="rAnimeBtn homeBannerBtn"
                  />
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="homeHeaderRighttBox">
                  <div className="image_container secondAnime">
                    <Lottie options={defaultOptions2} className="animeGIf" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex">
              <div className="col-md-12 col-lg-6">
                <div className="homeHeaderLeftBox">
                  <div className="headerMainTextWrap">
                    <h1>
                      <span className="turstedText">Cutting-Edge</span>

                      <span className="devText">e-Commerce</span>

                      <span className="partnerText">Software</span>
                    </h1>
                  </div>
                  <p className="homePara">
                    Our team specialize in developing customized e-commerce
                    websites that are optimized for user experience, search
                    engine visibility, increase sales, and take your business to
                    the next level.
                  </p>
                  <RoundedCommonBtn
                    rText="Know More"
                    rLink="/software-development"
                    rName="rAnimeBtn homeBannerBtn"
                  />
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="homeHeaderRighttBox ">
                  <div className="image_container thirdAnime">
                    <Lottie options={defaultOptions3} className="animeGIf" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex">
              <div className="col-md-12 col-lg-6">
                <div className="homeHeaderLeftBox">
                  <div className="headerMainTextWrap">
                    <h1>
                      <span className="turstedText">Better UI/UX</span>

                      <span className="devText">Engagement</span>

                      <span className="partnerText">Expertise</span>
                    </h1>
                  </div>
                  <p className="homePara">
                    Whether you need a new website design or a redesign of an
                    existing one with our UI/UX design services, you can improve
                    customer satisfaction, increase conversions, and
                    grow your business.
                  </p>
                  <RoundedCommonBtn
                    rText="Know More"
                    rLink="/design-and-graphics"
                    rName="rAnimeBtn homeBannerBtn"
                  />
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="homeHeaderRighttBox">
                  <div className="image_container fourthAnime">
                    <Lottie options={defaultOptions4} className="animeGIf" />
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>

      <section className="WhyChooseUsNewSections" id="WhyChooseUsNewSections">
        <div className="custom-shape-divider-top-1685346802">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              className="shape-fill"
            ></path>
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              className="shape-fill"
            ></path>
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
        <WhyChooseUsNew />
      </section>
      <section className="gsapSection">
        <ScrollTriggerPage />
      </section>

      <section id="ourProcess" className="ourProcess">
        {
          // <ActionableReports />
        }
        <OurProcess />
      </section>
      <section id="clients" className="clients">
        <Clients />
      </section>
      <section id="industriesSection" className="industriesSection">
        <div className="leftTop"></div>
        <div className="rightTop"></div>
        <Industries
          char1="Our"
          char2="Services"
          char3="In"
          char4="Industries"
          secPara="We have honed our skills in developing advanced web platforms,
  mobile applications, and intricate systems that align with the
  latest industry trends."
          icon1="health_and_safety"
          cardTitle1="Health and Life Sciences"
          cardDesc1="EHR & EMR, Patient Portals software development services
          for institutions maintaining physical and mental
          well-being: medical, telemedicine."
          cardLink1="/healthcare"
          icon2="school"
          cardTitle2="Education"
          cardDesc2="Education app development services provided by Version
          Next empower learning experience for students, teachers,
          and administrations."
          cardLink2="/education-software-development-company"
          icon3="account_balance"
          cardTitle3="FinTech"
          cardDesc3="Financial software development services that help automate
          processes, improve transaction security, and put the
          latest technologies for your business."
          cardLink3="/fin-tech"
          icon4="volunteer_activism"
          cardTitle4="Insurance"
          cardDesc4="Insurance software development services that automate
          processes, improve data security, and help your business
          benefit from the latest technologies."
          cardLink4="/insurance"
          icon5="inventory"
          cardTitle5="Supply Chain & Logistics"
          cardDesc5="We ensure consistent development, scaling, and high
          performance products for supply chain & logistics
          management to meet your corporate goals."
          cardLink5="/logistics-software-solutions"
          icon6="storefront"
          cardTitle6="Digital Retail"
          cardDesc6="Solutions counting customer’s business processes,
          Increasing the performance of customer’s retail software
          by automating manual processes."
          cardLink6="/digital-retail"
        />
        <div className="rightBtm"></div>
        <div className="leftBtm"></div>
      </section>
      <section id="technologyStack" className="technologyStack">
        <TechStack />
      </section>
      <section className="meetingFixed" id="meetingFixed">
        <MeetingFixed />
      </section>

      <section id="case_study" className="case_study">
        <CaseStudyNew
          titleChar1="Our"
          titleChar2="Work"
          projectTitle10="1. Utsav Jewels"
          projectPara11="Utssav Jwelles had a strong presence in the offline market but wanted to expand their reach to a wider audience by selling their products online. They approached us at Version Next to help them needed a user-friendly e-commerce website that provided a seamless shopping experience to their customers."
          projectPara12="To address Utssav Jwelles' requirements, we designed and developed an e-commerce with mobile app for Utssav Jwelles to manage their orders and deliveries. The app allowed them to view all orders and track their status in real-time. The application was built using a modern stack of technologies to ensure fast loading times and smooth checkout processes."
          projectPara13="At Version Next, we take pride in delivering high-quality solutions that are tailored to our client's business needs. Our collaboration with Utssav Jwelles is an example of how we leverage our expertise in web and mobile app development to help businesses achieve their goals."
          projectTitle20="2. Building an ERP Tool for Heritage"
          projectPara21="Heritage, a leading company in the industry, sought to enhance their customer experience by developing an ERP tool. This tool aimed to seamlessly connect customers with the ability to order subscription-based products through their app. Additionally, it would establish a connection to the nearest Heritage branch responsible for fulfilling the orders and facilitating timely deliveries. Version Next, an experienced IT services provider, was enlisted to build this innovative solution."
          projectPara22="Version Next's development team meticulously crafted the ERP tool, leveraging industry-standard technologies to ensure a robust and reliable solution. The user interface was designed with a customer-centric approach, focusing on simplicity and ease of use while offering a comprehensive ordering experience. The ERP tool transformed Heritage's customer experience by providing a convenient, user-friendly platform for ordering subscription-based products."
          projectPara23="The solution provided Heritage with a competitive edge by enhancing customer satisfaction, optimizing internal operations, and supporting future growth."
          projectTitle30="3. Parental Control App for MobiCID"
          projectPara31="MobiCID,has the goal was to create a powerful tool that would enable parents to monitor and manage their children's online activities effectively, approached Version Next with a specific requirement."
          projectPara32="Version Next collaborated closely with MobiCID to understand their specific needs and the desired functionalities of the parental control app. We implemented advanced tracking mechanisms to capture and present detailed reports on calling, app usage, websites visited, and social media interactions. The app provided parents with extensive customization options, allowing them to set screen time limits, block specific apps or websites, and receive notifications for concerning activities."
          projectPara33="The implementation of stringent privacy and security measures further enhanced the app's reliability and trustworthiness. Overall, the MobiCID parental control app became a valuable tool for proactive parental oversight in today's digital age."
          projectTitle40="4. Mobile Trading App for Elixir Equities"
          projectPara41="Elixir Equities, a prominent financial services company, approached our team with a specific requirement: to design and develop a mobile trading app that would enable their clients to engage in seamless trading activities using their API."
          projectPara42="We collaborated closely with Elixir Equities to understand their specific needs and the desired functionalities of the mobile trading app. We developed a robust backend system that seamlessly integrated with Elixir Equities' API, allowing real-time market data updates and immediate trade execution. The app was equipped with a wide range of trading features, including market orders, limit orders, real-time portfolio tracking, and historical trade data analysis."
          projectPara43="By providing an intuitive UI/UX design, seamless integration with the Elixir Equities API, and a wide range of trading features, the app revolutionised the trading experience for their clients. With a strong emphasis on security and compliance, the app offered peace of mind to users, knowing their information and transactions were protected."
          totlaCount="4"
        />
      </section>
      <section id="contactUs" className="contactUs">
        <ContactUs />
      </section>
    </div>
  );
}

export default HomePage;
