import React from "react";
import DevelopBannerSection from "../components/Homepage/DevelopPageComponents/DevelopBannerSection";
import DevServicesSection from "../components/Homepage/DevelopPageComponents/DevServicesSection";
import Industries from "../components/Homepage/IndustriesSection/Industries";
import VntWork from "../components/ServicesPageComponents/VntWork";
import DevDiff from "../components/Homepage/DevelopPageComponents/DevDiff";
import { Accodion } from "../components/Accordion/AccordionItem";
import FaqVideo from "../components/FaqVideo/FaqVideo";
import TransparentRoundedBtn from "../components/CTA/TransparentRoundedBtn";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WorkSliderItem from "../components/ServicesPageComponents/WorkSliderItem";
import IndusExpert from "../components/IndustriesPageComponents/IndusExpert";
import BlogSection from "../components/BlogPosts/BlogSection";
import { Helmet } from "react-helmet";
import {
  design1,
  design2,
  design3,
  design4,
  design5,
  float1,
  float2,
  vinayakprofileImg,
} from "../components";
function DesignGraphicsDev() {
  var settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: true,
    speed: 800,
    adaptiveHeight: false,
    infinite: true,
    useTransform: true,
    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
    <Helmet>
<title>Design & Graphics Services | Stand Out with Stunning Visuals</title>
<meta name="description" content="Elevate your brand with our professional design and graphics services. From logos to complete branding, our creative team delivers stunning visuals that captivate and engage." />

<link rel="canonical" href="https://version-next.com/design-and-graphics" />
    
<meta property="og:title" content="Design & Graphics Services | Stand Out with Stunning Visuals" />
<meta property="og:description" content="Elevate your brand with our professional design and graphics services. From logos to complete branding, our creative team delivers stunning visuals that captivate and engage." />
<meta property="og:url" content="https://version-next.com/design-and-graphics" />
<meta property="og:type" content="website" />
<meta property="og:image" content="https://version-next.com/static/media/versionnext-logo.abca9ec7.svg" />

<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="Design & Graphics Services | Stand Out with Stunning Visuals" />
<meta name="twitter:description" content="Elevate your brand with our professional design and graphics services. From logos to complete branding, our creative team delivers stunning visuals that captivate and engage." />
<meta name="twitter:image" content="https://version-next.com/static/media/versionnext-logo.abca9ec7.svg" />
</Helmet>
      <div className="devlopPage designPage" id="devlopPage">
        <div className="devTopSection uiuxBg">
          <DevelopBannerSection
            line1="Design Thinking and "
            line2="Strategy Integrated"
            line3="Shaping Our Approach"
            BannerPara="Creative designing is the process of generating innovative and visually appealing solutions to design problems. It involves combining artistic skills, technical knowledge, and a deep understanding of the target audience to produce unique and captivating designs across various mediums."
          />
          <div className="shapeSvg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <path
                  className="fil0"
                  d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="devCoreServiceSection">
          <DevServicesSection
            secTitle="Our core services"
            secMainTitle="VNT is expert in Design & Graphics"
            secDesc="VNT (Version Next Technologies) is a leading expert in the field of Design & Graphics. With a highly skilled team of creative professionals, we specialize in delivering exceptional design solutions that captivate audiences and make a lasting impact. From logo design and branding to graphic assets and visual communication, we combine artistic flair with strategic thinking to create visually stunning designs tailored to your specific needs. With our expertise in design and graphics, we help businesses stand out, convey their message effectively, and establish a strong visual identity in today's competitive market."
          />
        </div>
        <section id="industriesSection" className="industriesSection">
          <div className="rightTop"></div>
          <Industries
            char1="Our"
            char2="Services"
            char3="In"
            char4="Design & Graphics"
            secPara="We have honed our skills in developing advanced web platforms,
          mobile applications, and intricate systems that align with the
          latest industry trends."
            icon1="health_and_safety"
            cardTitle1="Branding"
            cardDesc1="In the realm of branding, we specialize in crafting compelling brand identities that leave a lasting impression.Our expert team delves deep into your brand."
            cardLink1="#"
            icon2="school"
            cardTitle2="Packaging"
            cardDesc2="Our packaging design services encompass the creation of visually appealing and functional packaging solutions. "
            cardLink2="#"
            icon3="account_balance"
            cardTitle3="UI/UX"
            cardDesc3="In UI/UX design, we specialize in creating user-centered and visually captivating digital experiences. By deeply understanding your target users."
            cardLink3="#"
            icon4="volunteer_activism"
            cardTitle4="Photography"
            cardDesc4="In photography, our expertise lies in capturing moments, telling stories, and creating visually stunning images that leave a lasting impact. "
            cardLink4="#"
            icon5="inventory"
            cardTitle5="Ad Campaigns"
            cardDesc5="In ad campaigns, our expertise lies in creating impactful and compelling advertising strategies that effectively communicate your brand message. "
            cardLink5="#"
          />
          <div className="leftBtm"></div>
        </section>
        <section className="wntWorkSection">
          <div className="container">
            <VntWork
              subText="How We"
              mainText="Work?"
              paratext="We follow a collaborative work process, begin with an in-depth consultation to understand your project requirements and objectives. Through thorough research and planning, we develop a strategic roadmap for your project. Our talented team of designers and developers bring your vision to life with creativity and expertise. We ensure effective communication, incorporate feedback, and deliver the final product on time and within budget."
            />
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-12 col-12">
                <div className="workSliderWrapper">
                  <Slider {...settings} className="sliderWork slider-with-gap">
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Our Processes"
                        number="1"
                        sliderDesc="Consultation and requirements gathering to understand project objectives and vision. Research and planning phase to develop a strategic roadmap for the design process."
                        slideImg={design1}
                      />
                    </div>

                    <div className="slide-item">
                      <WorkSliderItem
                        title="Analysis"
                        number="2"
                        sliderDesc="Conduct comprehensive market research and analysis to gain insights and identify key trends. Analyze user data and feedback to inform design decisions and optimize the user experience."
                        slideImg={design2}
                      />
                    </div>
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Planning Stage"
                        number="3"
                        sliderDesc="Develop a detailed project plan outlining objectives, timelines, and resource allocation. Create a strategic design plan, considering target audience, user needs, and desired outcomes."
                        slideImg={design3}
                      />
                    </div>
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Design"
                        number="4"
                        sliderDesc="Transform creative concepts into visually compelling and user-centric designs that captivate and communicate effectively. Apply design principles, aesthetics, and industry best practices to create visually appealing and functional solutions."
                        slideImg={design4}
                      />
                    </div>
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Development & QA"
                        number="5"
                        sliderDesc="Implement the design into a functional product using robust coding and development practices. Conduct thorough testing and quality assurance to ensure the product performs flawlessly and meets all requirements and specifications."
                        slideImg={design5}
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatMakesSection" id="whatMakesSection">
          <DevDiff
            float1={float1}
            float2={float2}
            Char1="What Makes"
            Char2="us"
            Char3="Different?"
            diffDescription="What sets us apart is our expertise, customization, collaboration, creativity, results-driven approach, and customer-centric focus. We have a highly skilled team, personalized solutions, open communication, innovative ideas, a focus on measurable results, and a dedication to customer satisfaction. These factors combine to deliver outstanding outcomes and exceptional experiences for our clients."
          />
        </section>
        <section className="IndusExpertSection">
          <IndusExpert
            cName="Version Next Technologies"
            cSecTitle="Why Hire Version Next Technologies as Branding Partner?"
            profileName="Mr. Vinayak Parab"
            designation="Buisness Head"
            cardTitle1="Strategic Branding Expertise"
            cardData1="Experienced in branding, we develop strategic solutions to position your brand, differentiate from competitors, and resonate with your target audience."
            cardTitle2="Creative Excellence"
            cardData2="Our team of creative professionals excels in visual design, storytelling, and impactful branding assets that leave a lasting impression."
            cardTitle3="Collaborative Partnership"
            cardData3="We value strong partnerships, involving you in the branding process. Open communication, transparency, and collaboration drive us towards creating a brand aligned with your vision."
            cardTitle4="Quality"
            cardData4="Our comprehensive branding services cover everything from strategy to visual identity, delivering exceptional results for your brand's success."
            profileImg={vinayakprofileImg}
            linkdinLink="http://linkedin.com/in/vinayak-parab-2a7a8615"
            linkdinIcon="fab fa-linkedin"
          />
        </section>
        <section className="commonFaq">
          <div className="container">
            <div className="sectionTitle">
              <h2>
                Frequently Asked <span className="textGrade">Questions</span>
              </h2>

              <p>
                Singling out common points and possible difficulties which can
                be encountered in EHR, EMR, and Patient Portal software
                development
              </p>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6 col-12">
                <Accodion />
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <FaqVideo />
              </div>
            </div>
            <div className="readMoreBtnWrap">
              <TransparentRoundedBtn
                rText="Read More"
                rLink="/faqs"
                rName="rAnimeBtn readMoretBtn"
              />
            </div>
          </div>
        </section>
        <BlogSection />
      </div>
    </>
  );
}

export default DesignGraphicsDev;
