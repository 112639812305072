import React from "react";
import DevelopBannerSection from "../components/Homepage/DevelopPageComponents/DevelopBannerSection";
import DevServicesSection from "../components/Homepage/DevelopPageComponents/DevServicesSection";
import Industries from "../components/Homepage/IndustriesSection/Industries";
import VntWork from "../components/ServicesPageComponents/VntWork";
import DevDiff from "../components/Homepage/DevelopPageComponents/DevDiff";
import { Accodion } from "../components/Accordion/AccordionItem";
import FaqVideo from "../components/FaqVideo/FaqVideo";
import TransparentRoundedBtn from "../components/CTA/TransparentRoundedBtn";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WorkSliderItem from "../components/ServicesPageComponents/WorkSliderItem";
import { float1, float2, summetprofileImg } from "../components";
import IndusExpert from "../components/IndustriesPageComponents/IndusExpert";
import NativeServicesBlocks from "../components/DevPageComponents/NativeServicesBlocks";
import { Helmet } from "react-helmet";
function ReactNativeDev() {
  var settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: true,
    speed: 800,
    adaptiveHeight: false,
    infinite: true,
    useTransform: true,
    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
    <Helmet>
<title>React Native App Development Services for Cross-Platform Solutions</title>
<meta name="description" content="Develop cross-platform mobile apps with our React-Native development services. We create high-performance apps that run seamlessly on both iOS and Android devices." />
<link rel="canonical" href="https://version-next.com/react-native-development" />
<meta property="og:title" content="React-Native App Development Services for Cross-Platform Solutions" />
<meta property="og:description" content="Develop cross-platform mobile apps with our React-Native development services. We create high-performance apps that run seamlessly on both iOS and Android devices." />
<meta property="og:url" content="https://version-next.com/react-native-development" />
<meta property="og:type" content="website" />
<meta property="og:image" content="https://version-next.com/static/media/versionnext-logo.abca9ec7.svg" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="React-Native App Development Services for Cross-Platform Solutions" />
<meta name="twitter:description" content="Develop cross-platform mobile apps with our React-Native development services. We create high-performance apps that run seamlessly on both iOS and Android devices." />
<meta name="twitter:image" content="https://version-next.com/static/media/versionnext-logo.abca9ec7.svg" />
</Helmet>
      <div className="devlopPage" id="devlopPage">
        <div className="devTopSection reactNativeBg">
          <DevelopBannerSection
            line1="Native"
            line2="Mobile App"
            line3="Development Services"
            BannerPara="Building beautiful, cross-platform mobile apps can be difficult without a skilled team of developers. At VNT's, we specialize in creating React Native apps for iOS, Windows, and Android platforms. We offer comprehensive support, including QA, testing, and maintenance, to ensure your app performs flawlessly."
          />
          <div className="shapeSvg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <path
                  className="fil0"
                  d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="devCoreServiceSection">
          <DevServicesSection
            secTitle="Our core services"
            secMainTitle="VNT is expert in React Native Development"
            secDesc="VNT is a leading React Native development company that specializes in building high-quality, scalable, and user-friendly mobile apps. We have a team of experienced React Native developers who are experts in all aspects of the development process, from ideation to deployment."
          />
        </div>
        <section id="yourNeedSection" className="pt-5">
          <NativeServicesBlocks />
        </section>
        <section id="industriesSection" className="industriesSection">
          <div className="rightTop"></div>
          <Industries
            char1="Our"
            char2="Services"
            char3="In"
            char4="Industries"
            secPara="We have honed our skills in developing advanced web platforms,
        mobile applications, and intricate systems that align with the
        latest industry trends."
            icon1="health_and_safety"
            cardTitle1="Health and Life Sciences"
            cardDesc1="EHR & EMR, Patient Portals software development services
          for institutions maintaining physical and mental
          well-being: medical, telemedicine."
            cardLink1="/healthcare"
            icon2="school"
            cardTitle2="Education"
            cardDesc2="Education app development services provided by Version
          Next empower learning experience for students, teachers,
          and administrations."
            cardLink2="/education-software-development-company"
            icon3="account_balance"
            cardTitle3="FinTech"
            cardDesc3="Financial software development services that help automate
          processes, improve transaction security, and put the
          latest technologies for your business."
            cardLink3="/fin-tech"
            icon4="volunteer_activism"
            cardTitle4="Insurance"
            cardDesc4="Insurance software development services that automate
          processes, improve data security, and help your business
          benefit from the latest technologies."
            cardLink4="/insurance"
            icon5="inventory"
            cardTitle5="Supply Chain & Logistics"
            cardDesc5="We ensure consistent development, scaling, and high
          performance products for supply chain & logistics
          management to meet your corporate goals."
            cardLink5="/logistics-software-solutions"
            icon6="storefront"
            cardTitle6="Digital Retail"
            cardDesc6="Solutions counting customer’s business processes,
          Increasing the performance of customer’s retail software
          by automating manual processes."
            cardLink6="/digital-retail"
          />
          <div className="leftBtm"></div>
        </section>
        <section className="wntWorkSection">
          <div className="container">
            <VntWork
              subText="How We"
              mainText="Work?"
              paratext="We employ top-notch teams and cutting-edge technologies to guarantee a smooth, comprehensive software development process. As a full-cycle product development service provider, we prioritise building, testing, and deploying your product to align with your business needs, regardless of its size or industry."
            />
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-12 col-12">
                <div className="workSliderWrapper">
                  <Slider {...settings} className="sliderWork slider-with-gap">
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Our Processes"
                        number="1"
                        sliderDesc="Analysis. Planning Stage. Design.
                        Development & QA. Launch.
                        Maintenance and Growth."
                        slideImg="https://version-next.com/static/media/process_gears_vnt.16bf3032.png"
                      />
                    </div>

                    <div className="slide-item">
                      <WorkSliderItem
                        title="Analysis"
                        number="2"
                        sliderDesc="Product goals and strategy are analysed. Project structure and roles are defined. Optimal methodology and approach for robust development are determined."
                        slideImg="https://version-next.com/static/media/analysis_tab.f27c8444.png"
                      />
                    </div>
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Planning Stage"
                        number="3"
                        sliderDesc="The project's detailed structure is outlined, including user-story descriptions of functionality and UX design considerations. An accurate development estimation is provided."
                        slideImg="https://version-next.com/static/media/planning_process_vnt.083bd76f.png"
                      />
                    </div>
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Design"
                        number="4"
                        sliderDesc="Product requirements are explored, research data is leveraged, a prototype is built, a design concept is created, and the design is tested for optimal user experience."
                        slideImg="https://version-next.com/static/media/ui-design-process-flow.8c148e74.png"
                      />
                    </div>
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Development & QA"
                        number="5"
                        sliderDesc="We adapt our development process to align with the project's environment, ensuring an effective methodology."
                        slideImg="https://version-next.com/static/media/sc-4.1b330c8f.png"
                      />
                    </div>
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Launch"
                        number="6"
                        sliderDesc="During this stage, all involved parties are prepared for the public launch. We utilize a CI/CD approach to deliver the product to end users efficiently and effectively."
                        slideImg="https://version-next.com/static/media/launch-version-next.a570ccc4.jpg"
                      />
                    </div>
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Maintenance and Growth"
                        number="7"
                        sliderDesc="Providing Technical and Customer Support while Setting Key Metrics for Critical Growth Indicators and Utilising an Iterative Approach for Continuous Growth Strategy."
                        slideImg="https://version-next.com/static/media/sc-6.3a6eed7e.png"
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatMakesSection" id="whatMakesSection">
          <DevDiff
            float1={float1}
            float2={float2}
            Char1="What Makes"
            Char2="us"
            Char3="Different?"
            diffDescription="What sets us apart is our expertise, customization, collaboration, creativity, results-driven approach, and customer-centric focus. We have a highly skilled team, personalized solutions, open communication, innovative ideas, a focus on measurable results, and a dedication to customer satisfaction. These factors combine to deliver outstanding outcomes and exceptional experiences for our clients."
          />
        </section>
        <section className="IndusExpertSection">
          <IndusExpert
            cName="Version Next Technologies"
            cSecTitle="Why Hire Version Next Technologies For React Native Development"
            profileName="Mr. Sumeet Rathod"
            designation="Buisness Head"
            cardTitle1="Innovation"
            cardData1="We brings innovative solutions that leverage cutting-edge technologies to drive business growth and stay ahead of the competition."
            cardTitle2="Integrity"
            cardData2="We uphold the highest standards of integrity, ensuring transparency, honesty, and ethical practices in all our interactions with clients."
            cardTitle3="Customer-Obsession"
            cardData3="Our unwavering focus on delivering exceptional customer experiences sets us apart, as we go above and beyond to meet and exceed client expectations."
            cardTitle4="Quality"
            cardData4="We are committed to delivering top-notch quality in all our services, adhering to industry best practices and rigorous quality control processes to ensure excellence."
            profileImg={summetprofileImg}
            facebookLink="https://www.linkedin.com/in/sumeetrathod"
            facebookIcon="fab fa-linkedin"
            twiiterLink="https://twitter.com/srtrend"
            twitterIcon="fab fa-twitter-square"
          />
        </section>
        <section className="commonFaq d-none">
          <div className="container">
            <div className="sectionTitle">
              <h2>
                Frequently Asked <span className="textGrade">Questions</span>
              </h2>

              <p>
                Singling out common points and possible difficulties which can
                be encountered in EHR, EMR, and Patient Portal software
                development
              </p>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6 col-12">
                <Accodion />
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <FaqVideo />
              </div>
            </div>
            <div className="readMoreBtnWrap">
              <TransparentRoundedBtn
                rText="Read More"
                rLink="/faqs"
                rName="rAnimeBtn readMoretBtn"
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ReactNativeDev;
