import React from "react";
import PageBanner from "../components/TermsPageComponents/PageBanner";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { loading } from "../components";
import { Helmet } from "react-helmet";
function BlogPage() {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(9);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          "https://blog.version-next.com/wp-json/wp/v2/posts?_embed"
        );
        setPosts(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, []);
  const staticImages = [
    "https://version-next.com/static/media/meetimg2.b2615b0b.jpg",
    "https://version-next.com/static/media/meetimg1.6c3c4022.jpg",
    "https://version-next.com/static/media/meetimg2.b2615b0b.jpg",
    "https://version-next.com/static/media/about-expert.7a10909f.png",
    "https://version-next.com/static/media/meetimg1.6c3c4022.jpg",
    "https://version-next.com/static/media/meetimg2.b2615b0b.jpg",
  ];

  const staticColors = [
    "#859500",
    "#e7b800",
    "#00a0b7",
    "#ca1d00",
    "#0064b9",
    "#202020",
  ];

  const getFeaturedMedia = (post) => {
    if (
      post._embedded &&
      post._embedded["wp:featuredmedia"] &&
      post._embedded["wp:featuredmedia"][0].source_url
    ) {
      return post._embedded["wp:featuredmedia"][0].source_url;
    } else {
      return staticImages[Math.floor(Math.random() * staticImages.length)];
    }
  };

  const getCategoryName = (post) => {
    if (
      post._embedded &&
      post._embedded["wp:term"] &&
      post._embedded["wp:term"][0] &&
      post._embedded["wp:term"][0][0] &&
      post._embedded["wp:term"][0][0].name
    ) {
      return post._embedded["wp:term"][0][0].name;
    } else {
      return "";
    }
  };
  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <section id="termsPage" className="termsPage">
      {// isLoading && <Lottie options={defaultOptions} className="animeGIf"/>
      isLoading && (
        <div className="loadingBox">
          <img src={loading} alt="Loading..." />
        </div>
      )}

      <>
      <Helmet>
        <title>Blog | Insights & Trends by Version Next</title>
        <meta name="description" content="Stay informed with the latest industry insights, trends, and updates from Version Next. Explore our blog to learn about digital transformation, tech, and more." />
      </Helmet>
        <div className="pagesBanner">
          <PageBanner PageTitle="Our Blog" />
          <div className="shapeSvg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <path
                  className="fil0"
                  d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
                />
              </g>
            </svg>
          </div>
        </div>

        <div className=" termsTopSection">
          <div className="container">
            <div className="row gy-5">
              {!isLoading && (
                <>
                  {currentPosts.map((post, index) => {
                    const postDate = new Date(post.date).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    );
                    const featuredMedia = getFeaturedMedia(post);
                    const categoryName = getCategoryName(post);
                    const backgroundColor =
                      staticColors[index % staticColors.length];

                    return (
                      <div className="slide-item col-md-4" key={index}>
                        <div className="blogBoxWrap">
                          <div
                            className="blog_badge"
                            style={{ backgroundColor }}
                          >
                            <span>{categoryName}</span>
                          </div>
                          <Link to={post.link} target="_blank">
                            <div className="blogImgWrap">
                              <img src={featuredMedia} alt="Blog Post" />
                            </div>
                            <div className="blogTitle">
                              <h6>{post.title.rendered}</h6>
                              <div className="blogSummery">
                                <p>{post.yoast_head_json.author}</p>
                                <p>
                                  <span className="material-symbols-outlined">
                                    calendar_month
                                  </span>{" "}
                                  {postDate}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })}

                  <div className="blogPagination">
                    {Array.from({
                      length: Math.ceil(posts.length / postsPerPage),
                    }).map((_, index) => (
                      <button
                        className="btn"
                        key={index}
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    </section>
  );
}

export default BlogPage;
